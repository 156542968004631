<template>
  <div>
    <!-- Form: Contract Info Form -->
    <b-form class="mt-1">
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: client -->
          <validation-provider
            #default="validationContext"
            vid="client"
            name="client"
            rules="required"
          >
            <b-form-group
              label="العميل"
              label-for="client"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="contractData.client_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="filteredClients"
                :reduce="val => val.value"
                :clearable="false"
                input-id="client"
                @input="clientChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>                   
        <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <!-- Field: quotation_id -->
              <validation-provider
                #default="validationContext"
                vid="quotation_id"
                name="quotation_id"
                rules="required"
              >
                <b-form-group
                  label="عرض السعر *"
                  label-for="quotation_id"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="contractData.quotation_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="quotations"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    @input="quotationChanged($event)"
                    input-id="quotation_id"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: status -->
          <validation-provider
            #default="validationContext"
            vid="status"
            name="status"
            rules="required"
          >
            <b-form-group
              label="حالة العقد"
              label-for="status"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="contractData.status"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="statuses"
                :reduce="val => val.value"
                :clearable="false"
                input-id="status"
                @input="statusChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: starts_at -->
          <validation-provider
            #default="validationContext"
            vid="starts_at"
            name="starts_at"
            rules="required"
          >
            <b-form-group
              label="تاريخ بداية العقد"
              label-for="starts_at"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="contractData.starts_at"
                :config="config"
                class="form-control contract-edit-input"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: ends_at -->
          <validation-provider
            #default="validationContext"
            vid="ends_at"
            name="ends_at"
            rules="required"
          >
            <b-form-group
              label="تاريخ نهاية العقد"
              label-for="ends_at"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="contractData.ends_at"
                :config="config"
                class="form-control contract-edit-input"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: lang -->
          <validation-provider
            #default="validationContext"
            vid="lang"
            name="lang"
            rules="required"
          >
            <b-form-group
              label="اختيار اللغة"
              label-for="lang"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="contractData.lang"
                :options="langOptions"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                input-id="lang"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: certified_evaluator_id -->
          <validation-provider
            #default="validationContext"
            vid="certified_evaluator_id"
            name="certified_evaluator_id"
            rules="required"
          >
            <b-form-group
            label="المقيم المعتمد *"
              label-for="certified_evaluator_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="contractData.certified_evaluator_id"
                :options="evaluators"
                :reduce="(val) => val.value"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                input-id="certified_evaluator_id"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div class="d-flex">
            <feather-icon
              icon="UserIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              بيانات الأتعاب  <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="btn-sm"
                variant="primary"
                @click="addItem"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>إضافة عقار</span>
              </b-button>
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
      </b-row>
      <b-row
        v-for="(item, index) in contractData.costs"
        :id="item.id"
        :key="item.id"
        ref="row"
      >
        <b-col
          cols="12"
          md="2"
          lg="2"
        >
          <!-- Field: type -->
          <validation-provider
            #default="validationContext"
            vid="type"
            name="type"
            rules="required"
          >
            <b-form-group
              label="نوع الأتعاب"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="item.type"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="costTypes"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="type"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="item.type === 'variable'"
          cols="12"
          md="2"
          lg="2"
        >
          <!-- Field: property_classification_id -->
          <validation-provider
            #default="validationContext"
            vid="property_classification_id"
            name="property_classification_id"
            rules="required"
          >
            <b-form-group
              label="تصنيف العقار"
              label-for="property_classification_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="item.property_classification_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="classifications"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="property_classification_id"
                @input="propertyClassificationChanged(item, index)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="item.type === 'variable'"
          cols="12"
          md="3"
          lg="3"
        >
          <!-- Field: property_type_id -->
          <validation-provider
            #default="validationContext"
            vid="property_type_id"
            name="property_type_id"
            rules="required"
          >
            <b-form-group
              label="نوع العقار"
              label-for="property_type_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="item.property_type_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="filteredTypes"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="property_type_id"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="3"
          lg="3"
        >
          <!-- Field: cost -->
          <validation-provider
            #default="validationContext"
            vid="cost"
            name="cost"
            rules="required"
          >
            <b-form-group
              label="أتعاب تقييم العقار"
              label-for="cost"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="cost"
                v-model="item.cost"
                type="number"
                step="0.01"
                @input="updateGrossCost(item)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="3" lg="3">
              <!-- Field: gross_cost -->
              <validation-provider
                #default="validationContext"
                vid="gross_cost"
                name="gross_cost"
                rules="required"
              >
                <b-form-group
                  label="أتعاب شاملة للقيمة المضافة*"
                  label-for="gross_cost"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="gross_cost"
                    v-model="item.gross_cost"
                    type="number"
                    step="0.01"
                    @input="updateCost(item)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
        </b-col>
        <!-- Remove Button -->
        <b-col
          cols="12"
          md="2"
          lg="2"
          class="mb-50"
        >
          <b-button
            v-if="contractData.costs.length > 1"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>حدف</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateContract"
          >
            حفظ التغييرات
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="resetData()"
          >
            إعادة ضبط
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormInvalidFeedback, BFormTextarea, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import contractStore from '../contractStore';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [alertMessageMixin],
  props: {
    contractData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
      langOptions: ['العربية', 'الانجليزية'],
    };
  },
  methods: {
    updateGrossCost(item) {
      item.gross_cost = (item.cost * (1+ this.tax /100)).toFixed(2);
    },
    updateCost(item) {
      item.cost = (item.gross_cost / (1+ this.tax /100)).toFixed(2);
    },
    propertyClassificationChanged(item, index) {
      this.contractData.costs[index].property_type_id = null;
      this.filteredTypes = this.types.filter(
        (type) => type.property_classification_id === item.property_classification_id,
      );
    },
    addItem() {
      this.contractData.costs.push({
        type: 'fixed',
        cost: 0,
        gross_cost : 0,
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.contractData.costs.splice(index, 1);
    },
    resetData() {
      this.$emit('reset-contract-data');
    },
    clientChanged(client) {
      this.contractData.client_id = client;
      this.contractData.client_contact_id = null; // Set the selected contact to null
      this.contractData.quotation_id = null;
      const selectedClient = this.filteredClients.find((c) => c.id === client);
    if (selectedClient) {
      this.quotations = selectedClient.quotations.map((c) => ({
        label: c.reference,
        value: c.id,
        ...c,
      }));
    } else {
      this.quotations = [];
    }
    },
    quotationChanged(quotationId) {
    this.contractData.costs = [];

    const selectedQuotation = this.quotations.find(q => q.id === quotationId);
    if (selectedQuotation && selectedQuotation.costs) {
      selectedQuotation.costs.forEach(cost => {
        this.contractData.costs.push({
          type: 'variable',
          property_classification_id: cost.property_classification_id,
          property_type_id: cost.property_type_id,
          cost: cost.cost,
          gross_cost: cost.gross_cost,
        });
      });
    }
    },
    contactClientChanged(client_contact_id) {
      this.contractData.client_contact_id = client_contact_id;
    },
    statusChanged(status) {
      this.contractData.status_id = status;
    },
    updateContract() {
        // Remove certified_evaluator_id if it's null
      if (this.contractData.certified_evaluator_id === null) {
        delete this.contractData.certified_evaluator_id;
      }
      // Filter out entities with null cost
          this.contractData.costs.forEach(cost => {
        Object.keys(cost).forEach(key => {
          if (cost[key] === null) {
            delete cost[key];
          }
        });
      });      
      this.$store
        .dispatch('app-contract/updateContract', { contractData: this.contractData, id: this.contractData.id })
        .then((response) => {
          this.$toast.success(response.data.message);
          router.push('/contract').catch(() => {});
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },

  setup(props) {
    const CONTRACT_STORE_MODULE_NAME = 'contract';

    // Register contract
    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_STORE_MODULE_NAME);
    });
    const clients = ref([]);
    const filteredClients = ref([]);
    const quotations = ref([]);
    const res = store.dispatch('contract/fetchClients')
      .then((response) => {
        const { data } = response.data;
        clients.value = data.map((c) => ({ label: c.name, value: c.id, ...c }));
        const client = clients.value.find(client => client.value === props.contractData.client_id);
        quotations.value = client.quotations.map((c) => ({
          label: c.reference,
          value: c.id,
          ...c,
        }));
        filteredClients.value = clients.value
      });
    const costTypes = [
      {
        label: 'أتعاب موحدة',
        value: 'fixed',
      },
      {
        label: 'أتعاب تفصيلية',
        value: 'variable',
      },
    ];
    const statuses = [
      {
        label: 'نشط',
        value: 'نشط',
      },
      {
        label: 'ملغى',
        value: 'ملغى',
      },
      {
        label: 'منتهي',
        value: 'منتهي',
      },
    ];
    const oldContractData = JSON.parse(JSON.stringify(props.contractData));
    const contractData = ref(null);
    const resetcontractData = () => {
      contractData.value = JSON.parse(JSON.stringify(oldContractData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetcontractData);
    const evaluators = ref([]);
    store
      .dispatch('contract/fetchEvaluators', {
        id: 1,
      })
      .then((response) => {
        const { data } = response.data;
        if (data && data.length > 0) {
          evaluators.value = data
            .filter((evaluator) => evaluator.roles.some((role) => role.name === 'certified_evaluator'))
            .map((c) => ({
              label: c.name,
              value: c.id,
              ...c,
            }));
        } else {
          evaluators.value = [];
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          evaluators.value = undefined;
        }
      });
    const types = ref([]);
    const filteredTypes = ref([]);
    const classifications = ref([]);
    const tax = ref(0) ;
    store.dispatch('contract/fetchContractOptions').then((response) => {
      const { propertyTypes, propertyClassifications , tax_value } = response.data;
      tax.value = tax_value ;
      types.value = propertyTypes.map((c) => ({
        label: c.name,
        value: c.id,
        ...c,
      }));
      filteredTypes.value = types.value;
      classifications.value = propertyClassifications.map((c) => ({
        label: c.name,
        value: c.id,
        ...c,
      }));
    });
    return {
      costTypes,
      quotations,
      tax,
      evaluators,
      types,
      filteredTypes,
      classifications,
      refFormObserver,
      getValidationState,
      resetForm,
      clients,
      filteredClients,
      statuses,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
